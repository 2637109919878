import React from "react";
import {Survey} from "survey-react";
import {TrefikSurveyPage} from "../page";
import {Boombox, Heading, Text} from "@bulb/patterns";

export class TrefikSurvey extends Survey {
    renderTitle() {
        let title = null;
        let description = null;
        if (this.survey.title && this.survey.showTitle) {
            title = this.renderLocString(this.survey.locTitle);
            description = this.renderLocString(this.survey.locDescription);
        }

        return title ? (
            <Boombox>
                <Heading size="hippo">{title}</Heading>
                <Text>
                    {description}
                </Text>
            </Boombox>
        ) : null;
    }

    renderPage(page) {
        return (
            <TrefikSurveyPage
                survey={this.survey}
                page={page}
                css={this.css}
                creator={this}
            />
        );
    }
}
