import React from "react";
import {BrowserRouter as Router, Switch, Route,} from "react-router-dom";
import {QueryClient, QueryClientProvider} from 'react-query'
import {createGlobalStyle} from "styled-components";
import {getGlobalStyles} from "@bulb/patterns/utils/getGlobalStyles";
import {PageContainer} from "@bulb/patterns/styles/Layout/PageContainer";

import Quiz from '../routes/quiz';
import Home from '../routes/home';
import Footer from "./footer";
import {Header} from "./header";

const GlobalStyle = createGlobalStyle`
  ${getGlobalStyles()}
`;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 1,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchInterval: false,
            refetchOnMount: false
        }
    }
});

const App = () => (
    <Router>
        <GlobalStyle/>
        <QueryClientProvider client={queryClient}>
            <Header/>
            <PageContainer size="midi">
                <Switch>
                    <Route path="/:region/:school?">
                        <Quiz/>
                    </Route>
                    <Route path="/">
                        <Home/>
                    </Route>
                </Switch>
            </PageContainer>
            <Footer/>
        </QueryClientProvider>
    </Router>
)

export default App;
