import React from "react";
import {SurveyQuestion} from "survey-react";
import {Vspace} from "@bulb/patterns";

export class TrefikSurveyQuestion extends SurveyQuestion {
    renderElement() {
        let question = this.question;
        let cssClasses = question.cssClasses;
        let questionRender = this.renderQuestion();
        let header = this.renderHeader(question);
        let headerTop = question.hasTitleOnLeftTop ? header : null;
        let headerBottom = question.hasTitleOnBottom ? header : null;
        let descriptionUnderInput = question.hasDescriptionUnderInput
            ? this.renderDescription(cssClasses, true)
            : null;
        let comment =
            question && question.hasComment ? this.renderComment(cssClasses) : null;
        let errorsTop =
            this.creator.questionErrorLocation() === "top"
                ? this.renderErrors(cssClasses, "top")
                : null;
        let errorsBottom =
            this.creator.questionErrorLocation() === "bottom"
                ? this.renderErrors(cssClasses, "bottom")
                : null;

        return (
            <div
                ref={this.rootRef}
                id={question.id}
                role={question.ariaRole}
                aria-labelledby={question.hasTitle ? question.ariaTitleId : null}
            >
                {headerTop}
                <Vspace>
                    {errorsTop}
                    {questionRender}
                    {comment}
                    {errorsBottom}
                    {descriptionUnderInput}
                </Vspace>
                {headerBottom}
            </div>
        );
    }
}