import React from "react";
import {Heading, Vspace} from "@bulb/patterns";
import {Confirmation} from "@bulb/patterns/modules/Confirmation";

const Home = () => (
    <div>
        <Confirmation
            statusTitle="Leuk dat je er bent!"
            statusDescription="Vul de code in om te starten"
            highlightedContent={
                <Vspace>
                    <Heading size="dolphin" level="h3">Wat nu?</Heading>
                    <p>
                        Je juf of meester heeft van ons een code ontvangen voor de voorronde van de Grote
                        Verkeersquiz.
                        Met deze code krijg je toegang tot de quizvragen.
                    </p>

                    <p>
                        De vragen gaan over verschillende verkeerssituaties die je onderweg zelf kunt tegenkomen.
                        Het is
                        de
                        bedoeling dat je telkens het beste antwoord aankruist. Als jouw school bij de beste scholen
                        hoort,
                        gaan jullie als school naar de finale van de Grote Verkeersquiz!
                    </p>
                    {/*<RegionForm />*/}
                </Vspace>
            } />
    </div>
)

export default Home;