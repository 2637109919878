import React from "react";
import {Section} from "@bulb/patterns";
import SectionWrapper from "../sectionwrapper";

export const Header = () => {
    return (
        <SectionWrapper>
            <Section backgroundColor="#003366" textColor="#F2F2F2" curvedBottom={true} marginTop="0" />
        </SectionWrapper>
    )
}