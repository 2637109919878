import React from "react";
import {Section, Tupperware} from "@bulb/patterns";
import styled from "styled-components"

import logo from "./logo.webp"
import gemeente from "./gemeentelosser.png"
import SectionWrapper from "../sectionwrapper";

const FooterImage = styled.img`
    @media (max-width: 1024px) {
        padding-bottom: 8vh;
    }
    max-width: 200px;
`;

const Footer = () => {
    let segments = window.location.pathname.split("/");
    let images = [<FooterImage key="tref-ik" src={logo} alt="Tref-ik verkeersadvies"/>];
    if (segments[1] && segments[1].toLowerCase() === "losser") {
        images.push(<FooterImage key="gemeente" src={gemeente} alt="gemeente losser"/>)
    }
    return (
        <SectionWrapper>
            <Section backgroundColor="#003366" textColor="#F2F2F2" curvedTop={true} marginBottom="0" id="footer">
                <Tupperware>{images}</Tupperware>
            </Section>
        </SectionWrapper>
    )
}

export default Footer;