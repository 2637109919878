import React from "react";
import {ReactElementFactory, SurveyRow} from "survey-react";
import {Vspace} from "@bulb/patterns";

export class TrefikSurveyRow extends SurveyRow {
    renderElement() {
        let elements = null;
        if (this.row.isNeedRender) {
            elements = this.row.elements.map(element => this.createElement(element));
        }
        return (
            <Vspace>
                {elements}
            </Vspace>
        );
    }

    createElement(element) {
        let elementType = element.getType();
        if (!ReactElementFactory.Instance.isElementRegistered(elementType)) {
            elementType = "question";
        }

        return ReactElementFactory.Instance.createElement(elementType, {
            key: element.name,
            element,
            creator: this.creator,
            survey: this.survey,
            css: this.css,
        });
    }
}