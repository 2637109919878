import React from "react";
import {SurveyQuestionRadiogroup} from "survey-react"
import {RadioButtons} from "@bulb/patterns/modules/RadioButtons";

export class TrefikSurveyQuestionRadiogroup extends SurveyQuestionRadiogroup {
    renderElement() {
        return (
            <RadioButtons
                variant="vertical"
                id={this.question.inputId}
                // label={this.question.title}
                status="unknown"
                labelIsHeading={true}
                possibleValues={this.getPossibleValues(this.question.visibleChoices)}
                onChange={event => this.question.renderedValue = event.value}
                answerQuestion={value => this.question.renderedValue = value}
            />
        )
    }

    getPossibleValues(choices) {
        let items = [];
        for (let i = 0; i < choices.length; i++) {
            let item = choices[i];
            items.push({title: this.renderLocString(item.locText, this.textStyle), value: item.value});
        }
        return items;
    }
}
