import {createElement} from 'react';
import {ReactElementFactory, ReactQuestionFactory} from "survey-react";
import {useMutation, useQuery} from 'react-query'
import {useParams} from "react-router-dom";
import axios from "axios";

import {TrefikSurveyQuestionImage} from "../../components/survey/image";
import {TrefikSurveyQuestionRadiogroup} from "../../components/survey/radiogroup";
import {TrefikSurvey} from "../../components/survey/survey";
import {TrefikSurveyQuestion} from "../../components/survey/question";

ReactElementFactory.Instance.registerElement("question", props => {
    return createElement(TrefikSurveyQuestion, props);
});
ReactQuestionFactory.Instance.registerQuestion("image", (props) => {
    return createElement(TrefikSurveyQuestionImage, props);
});
ReactQuestionFactory.Instance.registerQuestion("radiogroup", (props) => {
    return createElement(TrefikSurveyQuestionRadiogroup, props);
});

const Quiz = () => {
    let {region, school} = useParams();
    let endpoint = `https://44mbepxlv9.execute-api.eu-west-1.amazonaws.com/api/regional-quizzes/${region.toLowerCase()}`;
    const mutation = useMutation((payload) => axios.post(`${endpoint}/responses`, payload));
    const {isLoading, isError, data: json, error} = useQuery(`survey-${region}`, async () => {
        const {data} = await axios.get(endpoint)
        return data
    })
    const submitSurvey = (survey, answers) => {
        let payload = {region: survey.region, version: survey.version, answers, school: school}
        mutation.mutate(payload)
    }

    if (isLoading) {
        return <span>Bezig met laden...</span>
    }

    if (isError) {
        return <span>Foutmelding: {error.message}</span>
    }

    return (
        <TrefikSurvey json={json} onComplete={(result) => submitSurvey(json, result.data)} locale="nl"/>
    )
}

export default Quiz;
