import React from "react";
import {SurveyPage} from "survey-react";
import {Vspace} from "@bulb/patterns";
import {TrefikSurveyRow} from "../row"

export class TrefikSurveyPage extends SurveyPage {
    renderElement() {
        let rows = this.renderRows();
        return (
            <Vspace spacing="sun">
                {rows}
            </Vspace>
        );
    }

    createRow(row) {
        return (
            <TrefikSurveyRow
                key={row.id}
                row={row}
                survey={this.survey}
                creator={this.creator}
            />
        );

    }
}
