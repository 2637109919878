import React from "react";
import {SurveyQuestionImage} from "survey-react";
import {Figure} from "@bulb/patterns/modules/Publication/Image/Figure";

export class TrefikSurveyQuestionImage extends SurveyQuestionImage {
    renderElement() {
        let cssClasses = this.question.cssClasses;
        let control = null;
        if (this.question.contentMode === "image") {
            control = (
                <Figure
                    src={this.question.imageLink}
                    alt={this.question.text || this.question.title}
                />
            );
        }
        if (this.question.contentMode === "video") {
            control = (
                <embed
                    className={cssClasses.image}
                    src={this.question.imageLink}
                    width={
                        this.question.imageWidth
                            ? `${this.question.imageWidth}px`
                            : undefined
                    }
                    height={
                        this.question.imageHeight
                            ? `${this.question.imageHeight}px`
                            : undefined
                    }
                />
            );
        }
        return (<div className={this.question.cssClasses.root}>{control}</div>)
    }
}